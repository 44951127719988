import React from 'react'
import WithdrawalTransactions from '../components/WithdrawalTransactions'

function WithdrawalHistory() {
  return (
    <div>
      <div></div>
      <div className="flex flex-row gap-4 w-full">
        <WithdrawalTransactions />
      </div>
    </div>
  )
}

export default WithdrawalHistory