import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function AddCustomProfitB() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [customerid, setCustomerId] = useState();
  const [principal_amount, setPrincipalAmount] = useState();
  const [total_profit_generated, setTotalProfitGenerated] = useState();
  const [remaning_profit, setRemaningProfit] = useState();
  const [net_profit, setNetProfit] = useState();
  const [total_withdrawal, setTotalWithdrawal] = useState();
  const [total_amount, setTotalAmount] = useState();
  const [user_profit, setUserProfit] = useState();
  const [income_tax_amount, setIncomeTaxAmount] = useState();

  useEffect(() => {
    if (state) {
      setCustomerId(state.cid)
      setPrincipalAmount(state.principal_amount)
      setTotalProfitGenerated(state.total_profit_generated)
      setRemaningProfit(state.remaning_profit)
      setNetProfit(state.net_profit)
      setTotalWithdrawal(state.total_withdrawal)
      setTotalAmount(state.total_amount)
      setUserProfit(state.user_profit)
      setIncomeTaxAmount(state.income_tax_amount)
    }
  }, [])

  // const [customerIdError, setCustomerIdError] = useState();
  const [principalAmountError, setPrincipalAmountError] = useState();
  const [principalProfitAmountError, setTotalProfitGeneratedError] = useState();
  const [remaningProfitError, setRemaningProfitError] = useState();
  const [netProfitError, setNetProfitError] = useState();
  const [totalWithdrawalError, setTotalWithdrawalError] = useState();
  const [totalAmountError, setTotalAmountError] = useState("");
  const [userProfitError, setUserProfitError] = useState();
  const [incomeTaxAmountError, setIncomeTaxAmountError] = useState();

  const [apiError, setApiError] = useState("");


  const handleSubmit = (e) => {
    setPrincipalAmountError('')
    setTotalProfitGeneratedError('')
    setRemaningProfitError('')
    setNetProfitError('')
    setTotalWithdrawalError('')
    setTotalAmountError('')
    setUserProfitError('')
    setIncomeTaxAmountError('')
    // setUpiIdError('')

    e.preventDefault();
    let isValidCred = true;

    if (principal_amount === undefined || "" === principal_amount) {
      setPrincipalAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (total_profit_generated === undefined || "" === total_profit_generated) {
      setTotalProfitGeneratedError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (remaning_profit === undefined || "" === remaning_profit) {
      setRemaningProfitError("Please enter valid amount")
      isValidCred = false;
      return
    }

    if (net_profit === undefined || net_profit === false) {
      setNetProfitError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (total_withdrawal === undefined || total_withdrawal === false) {
      setTotalWithdrawalError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (total_amount === undefined || total_amount === false) {
      setTotalAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }

    if (user_profit === undefined || user_profit === false) {
      setUserProfitError("Please enter valid amount")
      isValidCred = false;
      return
    }

    if (income_tax_amount === undefined || income_tax_amount === false) {
      setUserProfitError("Please enter valid amount")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };
      axios.put(apiRoutes.profit + '/custombprofit', {
        'customerid': customerid,
        'principal_amount': principal_amount,
        'total_profit_generated': total_profit_generated,
        'remaning_profit': remaning_profit,
        'net_profit': net_profit,
        'total_withdrawal': total_withdrawal,
        'total_amount': total_amount,
        'income_tax_amount': income_tax_amount,
        'user_profit': user_profit
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              const userData = response.data.data;
              navigate('/manage-custom-profile')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }


  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Add Custom B Profile Profit Details
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="principal_amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter invested amount*</label>
                  <input type="number" name="principal_amount" value={principal_amount} id="principal_amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setPrincipalAmount(e.target.value)} />
                  <label className="errorLabel">{principalAmountError}</label>
                </div>

                <div>
                  <label htmlFor="total_profit_generated" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer total profit generated after 10 July amount*</label>
                  <input type="number" name="total_profit_generated" value={total_profit_generated} id="total_profit_generated" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setTotalProfitGenerated(e.target.value)} />
                  <label className="errorLabel">{principalProfitAmountError}</label>
                </div>
                <div>
                  <label htmlFor="income_tax_amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer income tax amount*</label>
                  <input type="number" name="income_tax_amount" value={income_tax_amount} id="income_tax_amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setIncomeTaxAmount(e.target.value)} />
                  <label className="errorLabel">{incomeTaxAmountError}</label>
                </div>
                <div>
                  <label htmlFor="net_profit" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer total net profit amount*</label>
                  <input type="number" name="net_profit" id="net_profit" value={net_profit} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => setNetProfit(e.target.value)} />
                  <label className="errorLabel">{netProfitError}</label>
                </div>
                <div>
                  <label htmlFor="remaning_profit" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer remaning profit amount*</label>
                  <input type="number" name="remaning_profit" value={remaning_profit} id="remaning_profit" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => setRemaningProfit(e.target.value)} />
                  <label className="errorLabel">{remaningProfitError}</label>
                </div>
                <div>
                  <label htmlFor="user_profit" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer profit amount*</label>
                  <input type="number" name="user_profit" value={user_profit} id="user_profit" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setUserProfit(e.target.value)} />
                  <label className="errorLabel">{userProfitError}</label>
                </div>

                <div>
                  <label htmlFor="total_withdrawal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer total withdrawal*</label>
                  <input type="number" name="total_withdrawal" value={total_withdrawal} id="total_withdrawal" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => setTotalWithdrawal(e.target.value)} />
                  <label className="errorLabel">{totalWithdrawalError}</label>
                </div>
                <div>
                  <label htmlFor="total_amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter total amount*</label>
                  <input type="number" name="total_amount" value={total_amount} id="total_amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setTotalAmount(e.target.value)} />
                  <label className="errorLabel">{totalAmountError}</label>
                </div>

                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Add profit details</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddCustomProfitB