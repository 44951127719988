import { format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import { getOrderStatus } from '../lib/helpers'
import axios from 'axios'
// import apiRoutes from '../../components/common/apiRoutes.json';
import apiRoutes from '../components/common/apiRoutes.json';
import React, { useState, useEffect } from 'react'

const recentOrderData = [
	{
		id: '1',
		product_id: '4324',
		customer_id: '23143',
		customer_name: 'ABC',
		order_date: '2022-05-17T03:24:00',
		order_total: '435.50',
		current_order_status: 'PLACED',
		shipment_address: 'pune'
	},
	{
		id: '2',
		product_id: '4324',
		customer_id: '23143',
		customer_name: 'ABC',
		order_date: '2022-05-17T03:24:00',
		order_total: '435.50',
		current_order_status: 'CONFIRMED',
		shipment_address: 'shirur'
	},
	{
		id: '3',
		product_id: '4324',
		customer_id: '23143',
		customer_name: 'ABC',
		order_date: '2022-05-17T03:24:00',
		order_total: '435.50',
		current_order_status: 'SHIPPED',
		shipment_address: 'pune'
	},
	{
		id: '4',
		product_id: '4324',
		customer_id: '23143',
		customer_name: 'ABC',
		order_date: '2022-05-17T03:24:00',
		order_total: '435.50',
		current_order_status: 'SHIPPED',
		shipment_address: 'pne'
	}

]



export default function InvestmentTransactions() {
	const [data, setData] = useState([])
	const [temp, setTemp] = useState("")

	const navigate = useNavigate();

	useEffect(() => {
		const headers = {
			'Content-Type': 'application/json',
			'token': localStorage.getItem('token'),
		};

		axios
			.get(apiRoutes.investment + '/list', { headers })
			.then((response) => {
				if (response.status === 200) {
					if (response.data.status) {
						setData(response.data.data)
					}
				}
			})
	}, [temp])

	const viewRequest = (id) => {
		localStorage.setItem('iid', id);
		navigate('/update-investment-request')
	}

	return (
		<div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
			<strong className="text-gray-700 font-medium">Recent Investment Transactions</strong>
			<div className="border-x border-gray-200 rounded-sm mt-3">
				<table className="w-full text-gray-700">
					<thead>
						<tr>
							<th>Sr. No.</th>
							<th>Customer Name</th>
							<th>Investment Date</th>
							<th>Investment Amount</th>
							<th>Investment Status</th>
							<th>View</th>
						</tr>
					</thead>
					<tbody>
						{data.map((investment, index) => (
							<tr key={investment._id}>
								<td> {index + 1}] </td>
								<td> {investment.customer_name} </td>
								<td> {investment.investment_date} </td>
								{/* <td> {format(new Date(investment.investment_date), 'dd MMM yyyy')} </td> */}
								<td> {investment.amount} </td>
								<td> {investment.payment_status} </td>
								<td onClick={() => viewRequest(`${investment._id}`)}>View</td>
								{/* <td>
									<Link to={`/customer/${investment.id}`}>view</Link>
								</td> */}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
		// 	<tbody>
		// 	{data.map((order) => (
		// 		<tr key={order.id}>
		// 			<td>
		// 				<Link to={`/order/${order.id}`}>#{order.id}</Link>
		// 			</td>
		// 			<td>
		// 				<Link to={`/product/${order.product_id}`}>#{order.product_id}</Link>
		// 			</td>
		// 			<td>
		// 				<Link to={`/customer/${order.customer_id}`}>{order.customer_name}</Link>
		// 			</td>
		// 			<td>{format(new Date(order.order_date), 'dd MMM yyyy')}</td>
		// 			<td>{order.order_total}</td>
		// 			<td>{order.shipment_address}</td>
		// 			<td>{getOrderStatus(order.current_order_status)}</td>
		// 		</tr>
		// 	))}
		// </tbody>
	)
}
