import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';
import { HiOutlineSearch } from 'react-icons/hi'

function CustomerList() {

  const [data, setData] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [customer, setCustomer] = useState();
  const [customerError, setCustomerError] = useState();
  const [apiError, setApiError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };

    const metadata = {
      "headers": {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      },
      "params": {
        'limit': 50,
        'type': 'Pending'
      }
    }

    axios
      .get(apiRoutes.customer + '/list', metadata)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            setData(response.data.data)
          }
        }
      })
  }, [])

  const viewRequest = (id, action) => {
    localStorage.setItem('cid', id);
    if (action === 'Edit') {
      navigate('/update-customer')
    } else { navigate('/update-customer') }
  }

  const handleReportSubmit = (e) => {
    e.preventDefault();
    let isValidCred = true;
    setCustomerError('')
    setApiError('')

    if (customer === undefined || "" === customer) {
      setCustomerError("Enter valid customer details")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };

      axios.post(apiRoutes.customer + '/search', {
        'customer': customer
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              viewRequest(`${response.data.data.token}`, 'Edit')
            } else {
              setApiError(response.data.msg)
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <div>
      {showSearch ? (<div>
        <section className="bg-gray-50 dark:bg-gray-900">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Search Customer
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleReportSubmit} >
                <div>
                  <label htmlFor="customer" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer email/mobile/pan/adhar card no.</label>
                  <input type="text" name="customer" id="customer" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="email/mobile/pan/adhar card no"
                    required=""
                    onChange={(e) => setCustomer(e.target.value)} />
                  <label className="errorLabel">{customerError}</label>
                </div>

                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Search</button>
                <button onClick={() => setShowSearch(false)} className="w-full text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Cancel</button>
              </form>
            </div>
          </div>
        </section>
      </div>) : ''}
      {/* <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center" style={{ 'justify-content': 'flex-end' }}>
        <div className="pl-4 pr-4">
          <button className='investmentbtn bg-green-600 p-3 text-white rounded-md' type='button'>
            <Link to='/investment-request' style={{ 'color': 'white' }}>Add customer</Link>
          </button>
        </div>
      </div> */}
      <div className="flex flex-row gap-4 w-full">
        <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1 items-center" style={{ 'justify-content': 'flex-end' }}>
          <strong className="text-gray-700 font-medium">List of Customers</strong>
          {/* <button className='investmentbtn bg-green-600 p-3 text-white rounded-md' type='button'>
            <Link to='/investment-request' style={{ 'color': 'white' }}>Add customer</Link>
          </button> */}

          <button style={{ float: 'right', display: 'flex' }} onClick={() => setShowSearch(true)} ><HiOutlineSearch /> Search </button>
          <div className="border-x border-gray-200 rounded-sm mt-3">
            <table className="w-full text-gray-700">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  {/* <th>ID</th> */}
                  <th>Customer Full Name</th>
                  <th>Customer Profession</th>
                  <th>Customer Status</th>
                  {/* <th>View</th> */}
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {data.map((customer, index) => (
                  <tr key={customer._id}>
                    <td> {index + 1}] </td>
                    {/* <td> #{customer._id} </td> */}
                    <td> {customer.name} </td>
                    <td> {customer.profession} </td>
                    <td> {customer.status} </td>
                    {/* <td onClick={() => viewRequest(`${customer._id}`,'View')}>View</td> */}
                    <td onClick={() => viewRequest(`${customer._id}`, 'Edit')}>Edit</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerList