import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from './common/apiRoutes.json';
import React, { useState, useEffect } from 'react'

export default function WithdrawalTransactions() {
	const [data, setData] = useState([])
	const [temp, setTemp] = useState("")
	const navigate = useNavigate();

	const viewRequest = (id) => {
		localStorage.setItem('wid', id);
		navigate('/update-withdrawal-request')
	}

	useEffect(() => {
		const headers = {
			'Content-Type': 'application/json',
			'token': localStorage.getItem('token'),
		};

		axios
			.get(apiRoutes.withdrawal + '/list', { headers })
			.then((response) => {
				if (response.status === 200) {
					if (response.data.status) {
						setData(response.data.data)
					}
				}
			})
	}, [temp])

	return (
		<div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
			<strong className="text-gray-700 font-medium">Recent Withdrawal Transactions</strong>
			<div className="border-x border-gray-200 rounded-sm mt-3">
				<table className="w-full text-gray-700">
					<thead>
						<tr>
							<th>Sr. No.</th>
							<th>Customer Name</th>
							<th>Withdrawal Date</th>
							<th>Withdrawal Amount</th>
							<th>Withdrawal Status</th>
							<th>View</th>
						</tr>
					</thead>
					<tbody>
						{data.map((withdrawal, index) => (
							<tr key={withdrawal._id}>
								<td> {index + 1}] </td>
								<td> {withdrawal.customer_name} </td>
								<td> {withdrawal.withdrawal_date} </td>
								<td> {withdrawal.amount} </td>
								<td> {withdrawal.payment_status} </td>
								{/* <td> <Link to={`/update-withdrawal-request/${withdrawal._id}`}>View</Link> </td> */}
								<td onClick={() => viewRequest(`${withdrawal._id}`)}>View</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}
