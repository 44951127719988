import React, { useState } from 'react'
import InvestmentTransactions from '../components/InvestmentTransactions'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function AddInvestmentRequest() {
  const [customerid, setCustomerId] = useState();
  const [investment_date, setInvestmentDate] = useState();
  const [amount, setAmount] = useState();
  const [type, setType] = useState();
  const [transaction_id, setTransactionId] = useState();
  const [bank_name, setBankName] = useState();
  const [bank_account_no, setBankAccountNo] = useState("00");
  const [note, setNote] = useState();
  const [upi_id, setUpiId] = useState("NA");

  const [customerIdError, setCustomerIdError] = useState();
  const [investmentDateError, setInvestmentDateError] = useState();
  const [amountError, setAmountError] = useState();
  const [typeError, setTypeError] = useState();
  const [transactionIdError, setTransactionIdError] = useState();
  const [bankNameError, setBankNameError] = useState();
  const [bankAccountNoError, setBankAccountNoError] = useState("");
  const [noteError, setNoteError] = useState();
  const [upiIdError, setUpiIdError] = useState("");

  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setCustomerIdError('')
    setInvestmentDateError('')
    setAmountError('')
    setTypeError('')
    setTransactionIdError('')
    setBankNameError('')
    setBankAccountNoError('')
    setNoteError('')
    setUpiIdError('')

    e.preventDefault();
    let isValidCred = true;

    if (customerid === undefined || "" === customerid) {
      setCustomerIdError("Please enter customer email/mobile")
      isValidCred = false;
      return
    }
    if (amount === undefined || "" === amount) {
      setAmountError("Please enter amount")
      isValidCred = false;
      return
    }
    if (type === undefined || "" === type) {
      setTypeError("Please select valid payment type")
      isValidCred = false;
      return
    }

    if (bank_name === undefined || bank_name === false) {
      setBankNameError("Please enter valid bank name.")
      isValidCred = false;
      return
    }
    if (bank_account_no === undefined || bank_account_no === false) {
      setBankAccountNoError("Please enter valid bank account number Or type NA if not applicable.")
      isValidCred = false;
      return
    }
    if (transaction_id === undefined || transaction_id === false) {
      setTransactionIdError("Please enter valid Transaction ID or Transaction Number.")
      isValidCred = false;
      return
    }
    if (upi_id === undefined || upi_id === false) {
      setUpiIdError("Please enter valid bank UPI ID Or type NA if not applicable.")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };
      axios.post(apiRoutes.investment + '/add', {
        'customerid': customerid,
        'amount': amount,
        'investment_date': investment_date,
        'type': type,
        'transaction_id': transaction_id,
        'bank_name': bank_name,
        'bank_account_no': bank_account_no,
        'upi_id': upi_id,
        'note': note
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              const userData = response.data.data;
              navigate('/investment-requests')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }


  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Add Investment Transaction Details
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="customerid" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter investment customer email/mobile*</label>
                  <input type="text" name="customerid" id="customerid" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setCustomerId(e.target.value)} />
                  <label className="errorLabel">{customerIdError}</label>
                </div>
                <div>
                  <label htmlFor="investment_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter investment transaction date*</label>
                  <input type="date" name="investment_date" id="investment_date" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setInvestmentDate(e.target.value)} />
                  <label className="errorLabel">{investmentDateError}</label>
                </div>
                <div>
                  <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter investment amount*</label>
                  <input type="number" name="amount" id="amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setAmount(e.target.value)} />
                  <label className="errorLabel">{amountError}</label>
                </div>
                <div>
                  <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter payment type(UPI/IMPS)*</label>
                  <input type="text" name="type" id="type" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="UPI/IMPS"
                    required=""
                    onChange={(e) => setType(e.target.value)} />
                  <label className="errorLabel">{typeError}</label>
                </div>
                <div>
                  <label htmlFor="bank_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter bank name*</label>
                  <input type="text" name="bank_name" id="bank_name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setBankName(e.target.value)} />
                  <label className="errorLabel">{bankNameError}</label>
                </div>
                <div>
                  <label htmlFor="transaction_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Transaction ID/Number*</label>
                  <input type="text" name="transaction_id" id="transaction_id" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setTransactionId(e.target.value)} />
                  <label className="errorLabel">{transactionIdError}</label>
                </div>
                <div>
                  <label htmlFor="bank_account_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter bank account number (If payment type is IMPS)*</label>
                  <input type="number" name="bank_account_no" id="bank_account_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setBankAccountNo(e.target.value)} />
                  <label className="errorLabel">{bankAccountNoError}</label>
                </div>
                <div>
                  <label htmlFor="upi_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your UPI ID (If payment type is UPI)*</label>
                  <input type="text" name="upi_id" id="upi_id" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="abc@upi"
                    required=""
                    onChange={(e) => setUpiId(e.target.value)} />
                  <label className="errorLabel">{upiIdError}</label>
                </div>
                <div>
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter note if required</label>
                  <input type="text" name="note" id="note" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    onChange={(e) => setNote(e.target.value)} />
                  <label className="errorLabel">{noteError}</label>
                </div>
                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit Request</button>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddInvestmentRequest