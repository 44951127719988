import React, { useState } from 'react'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { HiOutlineLogout } from 'react-icons/hi'
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../lib/constants'
import logo from '../../assets/app-logo.jpg';

const linkClass =
	'flex items-center gap-2 font-light px-3 py-2 hover:bg-green-200 hover:no-underline active:bg-green-500 rounded-sm text-base'

export default function Sidebar() {
	
    const [collapsed, setCollapsed] = useState(false);
	const activeClass = 'color: #15a362, background: #edfdf6, borderleft: 3px solid #15a362, font-weight: 500';
	return (
		
		<div className="bg-white w-60 p-3 flex flex-col">
			<div className="flex items-center gap-2 px-1 py-3">
				{/* <FcBullish fontSize={24} />
				<span className="text-neutral-200 text-lg">OpenShop</span> */}
				<img src={logo}  />
			</div>
			<div className="py-8 flex flex-1 flex-col gap-0.5 text-black">
				{DASHBOARD_SIDEBAR_LINKS.map((link) => (
					<SidebarLink key={link.key} link={link} />
				))}
			</div>
			<div className="flex flex-col gap-0.5 pt-2 border-t border-green-700">
				{DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
					<SidebarLink key={link.key} link={link} />
				))}
				{/* <div className={classNames(linkClass, 'cursor-pointer text-red-500')}>
					<span className="text-xl">
						<HiOutlineLogout />
					</span>
					Logout
				</div> */}
			</div>
		</div>
	)
}

function SidebarLink({ link }) {
	const { pathname } = useLocation()

	return (
		<Link
			to={link.path}
			className={classNames(pathname === link.path ? 'bg-green-300 text-green-700 border-x-4 border-green-500' : 'text-neutral-400', linkClass)}
		>
			<span className="text-xl">{link.icon}</span>
			{link.label}
		</Link>
	)
}
