import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function UserProfile() {

  const [userData, setUserData] = useState({})
  const [temp, setTemp] = useState("")
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    setApiError("")

    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };
    axios.post(apiRoutes.user + '/profile', {"userid":1},{ headers })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status) {
            setUserData(response.data.data)
          } else {
            setApiError(response.data.msg)
          }
        }
      })
      .catch(err => console.log(err))

  }, [temp])

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                User Profile Details
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6">
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User full name: {userData?.name}</label>

                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User email: {userData?.email}</label>

                </div>
                <div>
                  <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User mobile number: {userData?.mobile}</label>

                </div>
                <div>
                  <label htmlFor="adhar_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User adhar card number: {userData?.adhar_card_no}</label>

                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User pan number: {userData?.pan_card_no}</label>
                </div>
                <div>
                  <label htmlFor="profession" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User account status: {userData?.status}</label>

                </div>
                <div>
                  <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User address: {userData?.address}</label>

                </div>
                <div>
                  <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User city: {userData?.city}</label>

                </div>
                <div>
                  <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User state: {userData?.state}</label>

                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User pincode: {userData?.pincode}</label>

                </div>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default UserProfile