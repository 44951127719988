import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function CustomCustomerList() {

  const [data, setData] = useState([])
  const [temp, setTemp] = useState("")

  const navigate = useNavigate();

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };

    axios
      .get(apiRoutes.customer + '/customcustomerlist', { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            setData(response.data.data)
          }
        }
      })
  }, [])

  const addProfit = (id, action, portfolio) => {
    if (action === 'Custom A') {
      navigate('/add-custom-profit', { state: { "cid": id, ...portfolio } })
    } else if (action === 'Custom B') {
      navigate('/add-customb-profit', { state: { "cid": id, ...portfolio } })
    }
  }

  return (
    <div>
      <div></div>
      <div className="flex flex-row gap-4 w-full">
        <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
          <strong className="text-gray-700 font-medium">List of custom profile customer</strong>
          <div className="border-x border-gray-200 rounded-sm mt-3">
            <table className="w-full text-gray-700">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Customer Full Name</th>
                  <th>Principal Amount</th>
                  <th>Total Profit</th>
                  <th>Total Net Amount</th>
                  <th>Total Withdrawal Amount</th>
                  <th>Profile Type</th>
                  <th>Add profit</th>
                </tr>
              </thead>
              <tbody>
                {data.map((customer, index) => (
                  (customer.profile_type === 'Custom B' ? (
                    <tr key={customer._id}>
                      <td> {index + 1}] </td>
                      <td> {customer.name} </td>
                      <td> {customer.portfolio?.principal_amount || 0} </td>
                      <td> {customer.portfolio?.net_profit || 0} </td>
                      <td> {customer.portfolio?.total_amount || 0} </td>
                      <td> {customer.portfolio?.total_withdrawal || 0} </td>
                      <td> {customer.profile_type} </td>
                      <td onClick={() => addProfit(`${customer._id}`, `${customer.profile_type}`, customer.portfolio)}>Add profit</td>
                    </tr>
                  ) : (
                    <tr key={customer._id}>
                      <td> {index + 1}] </td>
                      <td> {customer.name} </td>
                      <td> {customer.portfolio?.principal_amount || 0} </td>
                      <td> {customer.portfolio?.total_profit || 0} </td>
                      <td> {customer.portfolio?.net_amount || 0} </td>
                      <td> {customer.portfolio?.total_withdrawal || 0} </td>
                      <td> {customer.profile_type} </td>
                      <td onClick={() => addProfit(`${customer._id}`, `${customer.profile_type}`, customer.portfolio)}>Add profit</td>
                    </tr>
                  ))
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomCustomerList