import React from 'react'

function TermsAndConditions() {
  return (
    <div>

      <div>TermsAndConditions</div>
      <div>
        <ol style={{'margin': '10px', 'padding': '10px'}} type="1">
          <li>That the I NVESTOR hereby agrees to invest that are good, clean and cleared, entirely unencumbered and fully tax paid funds (hereinafter referred to as the “FUND”) to finance the project of the RECEIVER and hence the INVESTOR will remit the aforesaid amount of FUND to the bank account of the RECEIVER.</li>
          <li>On receiving the FUND in his bank account for the use in his project, the RECElVER shall give a receipt of aforesaid investment amount to the INVESTOR.</li>
          <li>The RECEIVER hereby agrees to accept the above investment and to share the profit but only after the project results in profits.</li>
          <li>The INVESTOR hereby confirms that in the condition of the project resulting in any losses or for additional funds requirements, the INVESTOR is not required to cover the losses or shortfall of funds and the RECEIVER will not seek any more funds to cover the losses or for additional funding.</li>
          <li>It is mutually agreed that the RECEIVER shall have absolute authority to implement his project and run the business associated with that project.</li>
          <li>It is understood and agreed by the Parties hereto that the INVESTOR shall not interfere in the day-to-day operations and overall functioning of the Business and the Projects of the RECEIVER.</li>
          <li>The RECEIVER is hereby authorized to allocate and use aforesaid FUND in the projects suitable to his business at his own will and the INVESTOR at no time will interfere in the investment decision of the RECEIVER and the RECEIVER is solely responsible for his decisions with regards to the use of FUND.</li>
          <li>The RECEIVER shall not violate any statuary provisions while running his business and implementing his projects; and damages or penalty, if any, due to the said statuary violation, or for any other reason shall occur, it will accrue only to the RECEIVER and the INVESTOR will remain indemnified against any such damages or penalty.	Should there be any dispute between the parties hereto, the same shall be settled amicably, failing which it could be settled under the applicable rules and prevailing acts of the Government of India. Appropriate Court of Law in INDIA shall have the jurisdiction on all matters.</li>
          <li>The investor principal amount shall be freeze for three months and after three months one-month prior notice will be given by investor in writing to receiver and same shall be return by receiver to investor.</li>
          <li>The profit withdrawal of investor may be withdrawal from receiver at ANY TIME with prior five-day notice to receiver. </li>
        </ol>
        <h1>  </h1>
        <h4>WE HAVE READ, UNDERSTOOD AND ACCEPTED THE TERMS & CONDITIONS CONTAINED HEREIN AND WE COMMIT TO COMPLY.</h4>
      </div>  </div>
  )
}

export default TermsAndConditions