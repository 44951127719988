import React from 'react'
import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'

export default function DashboardStatsGrid() {

	return (
		<div className="flex gap-4" style={{'flexWrap': 'wrap'}}>
			<BoxWrapper>
				{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
					<IoBagHandle className="text-2xl text-white" />
				</div> */}
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total investment</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">Rs.5,94,232</strong>
						{/* <span className="text-sm text-green-500 pl-2">+343</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
					<IoPieChart className="text-2xl text-white" />
				</div> */}
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Current Month Profit</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">Rs.30,423</strong>
						{/* <span className="text-sm text-green-500 pl-2">-343</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div> */}
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Customer</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">3,313</strong>
						{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="pl-4 pr-4">
					<button className='withdrawlbtn bg-red-600 p-3 text-white rounded-md' type='button'>
						<Link to='/add-profit' style={{'color':'white'}}>Add today's profit</Link>
					</button>
				</div>
			</BoxWrapper>
		</div>
	)
}

function BoxWrapper({ children }) {
	return <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">{children}</div>
}
