import React from 'react'

function PrivacyPolicy() {
  return (
    <div>
    <h1>PrivacyPolicy</h1>
    <p>This Privacy Policy identifies and describes the way Huberton Fintech Marketing and Consulting Private Limited ("Huberton Fintech ") uses and protects the information we collect about its Customers and Users. All uses of Huberton Fintech products and services, as well as visits to our websites, are subject to this Privacy Policy.
By using Huberton Fintech Website http://hubertonfintech.in and/or registering for and using its products and services, you consent to our Privacy Policy. In the event that you do not agree with and/or consent to the terms and conditions set out herein, please do not use or access the Website and use our products/services. You hereby acknowledge and agree that by use of the Website our products/services, you expressly consent Huberton Fintech collection, receipt, storage, processing, disclosure, transfer, dealing with and handling of your information in accordance with this Privacy Policy. You understand that while your account is active to use the Website and Services, Huberton Fintech , its subsidiaries, affiliates and third parties engaged by it, may retain your Information and use it in any manner in order to comply with its contractual obligations and other obligations under applicable laws.
</p>
    <p>
    DEFINITIONS - As used in this Policy, the terms listed below have the following meanings:<br />
Customer - A "Customer" is anyone who purchases/avails Huberton Fintech products or services.<br />
User - A "User" is anyone who visits our websites.<br />
Personal Information - "Personal Information" is information that directly identifies or reasonably can be used to identify an individual Customer or User. Personal Information does not include information that is readily available to the public domain.
Website - "Website" means any page or location on the Internet, no matter what device (cell phone, lap top, PC, etc.) or protocol (http, WAP, ftp or other) is used to access the webpage of http://hubertonfintech.in <br />
    </p>
    <ol style={{'margin': '10px', 'padding': '10px'}} type="1">
          <li>The Information we collect: We may collect different types of personal and other information based on your use of our products and services and our business relationship with you, including contact information, billing information, Huberton Fintech website usage, viewing and other technical information about your use of our network, services, products and the Website. Information may be collected when you give it to us with the intent to purchase or interact with us about a product or service we offer or provide; when you use any of the solutions provided by Huberton Fintech including but not limited to digital content, text, graphics, software and other materials that you may view on, access through, or upload to the product or services and when we collect it automatically when you visit our websites or use our products and services such as the type of operating system, the type of browser you are using, your IP address in order to provide better usability and personalized experience.</li>
          <li>Use of Information we collect: Our goal of collecting your information is to provide you with the best customer experience possible by communicating with you regarding service updates, offers and promotions; delivering advertising that may be of interest to you; investigating or taking action regarding illegal activities or violations of Website policies, as may be laid out in our Website from time to time. We collect some information on an anonymous basis and may anonymize the personal information we collect about you into data groups. We may share aggregate or anonymous information in various formats with third party entities, with whom we have contracted with to aggregate such data and may work with those entities to do research on our product and service offerings.</li>
          <li>Disclosure: We do not sell your Personal Information to anyone for any purpose, and we maintain information about you in our business records while you are a Customer, or until it is no longer needed for business, tax, or legal purposes. We have implemented encryption or other appropriate security controls to protect Personal Information when stored or transmitted by Huberton Fintech. Subject to applicable legal restrictions, under the Information Technology Act 2000 and its applicable rules, the Huberton Fintech group of companies may share your Personal Information with each other to make sure your experience is as seamless as possible, and you have the full benefit of what Huberton Fintech and its subsidiaries have to offer and we do not provide Personal Information to non- Huberton Fintech group of companies for the marketing of their own products and services without your consent. We may provide Personal Information to non- Huberton Fintech companies or other third parties for purposes such as pursuant to court orders, identity verification to prevent fraud and identity theft, enforcing of intellectual property rights or any disclosure required under any applicable law.</li>
          <li>Privacy Controls: You can review and correct your Personal Information collected by us. We are happy to correct information if found to be inaccurate. Customers and Users may verify that appropriate corrections have been made. However, it may be noted that Huberton Fintech, its subsidiaries, affiliates and third parties shall not be responsible for the accuracy and/or authenticity of the Information supplied by you and Huberton Fintech hereby disclaims any and all liability arising out of or in connection with the accuracy and/or authenticity of the Information.</li>
          <li>Cookies: We may use "cookies" to help you personalize your online experience. A cookie is a string of information that a website stores on a visitors/users computer, and that the visitors/users browser provides to the website each time the visitor/user returns. We may use cookies to identify and track visitors, their usage of our Website, and their website access preferences page views, date and time of visit, and other information about their use of the Website. If you do not wish to have cookies placed on your computer, you should set your browsers to refuse cookies before using Website. We also collect and may store information that your computer or mobile device provides to us in connection with your use of the Site such as your browser type, type of computer or mobile device, browser language, IP address, mobile carrier, unique device identifier, location, and requested and referring URLs. We may also use internal and third party analytics tools. The third party analytics companies we work with may combine the information collected with other information they have independently collected from other websites and/or other online products and services. Their collection and use of information is subject to their own privacy policies.</li>
          <li>Your Consent: In the event that you wish to withdraw your consent to Huberton Fintech collection, receipt, storage, processing, disclosure, transfer, dealing with and handling of the Information, you may contact Huberton Fintech at ceo@hubertonfintech.in, informing Huberton Fintech of your withdrawal of consent to its use as aforesaid of your Information. You hereby acknowledge and agree that in case you withdraw your consent to Huberton Fintech’s use and disclosure of your Information as provided for in this Privacy Policy, Huberton Fintech hereby expressly reserves the right to discontinue provision of access to the Website and Services at its own sole discretion.</li>
          <li>Minors: Huberton Fintech is not intended for or designed to attract users under the age of 18 and we do not intentionally or knowingly collect personal information on our Website from anyone under the age of 18. We encourage parents / guardians to be involved in the online activities of their children / minors to ensure that no information is collected from a child without parental / guardian permission.</li>
          <li>Links to Third Party Sites: This Privacy Policy only applies to information collected by Huberton Fintech. This Privacy Policy does not apply to the practices of companies that Huberton Fintech does not own or control, or employees that Huberton Fintech does not manage. Website may contain links to third party website(s). Any information you provide to, or that is collected by, third-party sites may be subject to the privacy policies of those sites, if any. Huberton Fintech urges you to acquaint yourself with the terms and conditions and privacy policy of every such Third Party Sites and in this regard, Huberton Fintech (including its subsidiaries/group companies) hereby expressly disclaims all liabilities with respect to the manner in which the Third Party Sites collects and/or uses the Information.</li>
          <li>Security: We have established electronic and administrative safeguards designed to secure the information we collect, to prevent unauthorized access to or disclosure of that information and to ensure it is used appropriately. Although we strive to keep your Personal Information secure, no security measures are absolute, and we cannot guarantee that your Personal Information will never be disclosed in a manner inconsistent with this Policy (for example, as the result of unauthorized acts by third parties that violate the law or this Policy). Huberton Fintech uses industry-standard technologies when transferring and receiving Customer's and Users data exchanged between Huberton Fintech and third parties to help ensure its security.</li>
          <li>Policy Changes and Updates: We reserve the right to update this Privacy Policy as necessary to reflect any changes we make and to satisfy legal requirements. If we make a material change to this Policy, we will post a prominent notice of the change on our websites, and provide you with other appropriate notice and choice regarding the use of your information. Please check our Website periodically for changes to this Privacy Policy. You hereby acknowledge and agree that your continued use of the Website and product and service offerings constitutes your agreement to this Privacy Policy and any updates hereto.</li>
          <li>Contact Us: Any questions or grievances about our practices or this Privacy Policy should be addressed to ceo@hubertonfintech.in</li>
          </ol>
    </div>
  )
}

export default PrivacyPolicy