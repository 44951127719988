import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function AddCustomProfit() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [customerid, setCustomerId] = useState();
  const [principal_amount, setPrincipalAmount] = useState();
  const [principal_profit_amount, setPrincipalProfitAmount] = useState();
  const [total_profit, setTotalProfit] = useState();
  const [net_amount, setNetAmount] = useState();
  const [total_withdrawal, setTotalWithdrawal] = useState();
  const [referral_principal_amount, setTotalReferralAmount] = useState();
  const [referral_principal_profit, setReferralPrincipalProfit] = useState();
  // const [total_referral_profit, setTotalReferralProfit] = useState("NA");

  useEffect(() => {
		if (state) {
      setCustomerId(state.cid)
      setPrincipalAmount(state.principal_amount || 0)
      setPrincipalProfitAmount(state.principal_profit_amount || 0)
      setTotalProfit(state.total_profit || 0)
      setNetAmount(state.net_amount || 0)
      setTotalWithdrawal(state.total_withdrawal || 0)
      setTotalReferralAmount(state.referral_principal_amount || 0)
      setReferralPrincipalProfit(state.referral_principal_profit || 0)
    }
	  },[]) 

  const [customerIdError, setCustomerIdError] = useState();
  const [principalAmountError, setPrincipalAmountError] = useState();
  const [principalProfitAmountError, setPrincipalProfitAmountError] = useState();
  const [totalProfitError, setTotalProfitError] = useState();
  const [netAmountError, setNetAmountError] = useState();
  const [totalWithdrawalError, setTotalWithdrawalError] = useState();
  const [totalReferralAmountError, setTotalReferralAmountError] = useState("");
  const [referralPrincipalProfitError, setReferralPrincipalProfitError] = useState();
  // const [totalReferralProfitError, setTotalReferralProfitError] = useState("");

  const [apiError, setApiError] = useState("");


  const handleSubmit = (e) => {
    setPrincipalAmountError('')
    setPrincipalProfitAmountError('')
    setTotalProfitError('')
    setNetAmountError('')
    setTotalWithdrawalError('')
    setTotalReferralAmountError('')
    setReferralPrincipalProfitError('')
    // setUpiIdError('')

    e.preventDefault();
    let isValidCred = true;

    if (principal_amount === undefined || "" === principal_amount) {
      setPrincipalAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (principal_profit_amount === undefined || "" === principal_profit_amount) {
      setPrincipalProfitAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (total_profit === undefined || "" === total_profit) {
      setTotalProfitError("Please enter valid amount")
      isValidCred = false;
      return
    }

    if (net_amount === undefined || net_amount === false) {
      setNetAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (total_withdrawal === undefined || total_withdrawal === false) {
      setTotalWithdrawalError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (referral_principal_amount === undefined || referral_principal_amount === false) {
      setTotalReferralAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }

    if (referral_principal_profit === undefined || referral_principal_profit === false) {
      setReferralPrincipalProfitError("Please enter valid amount")
      isValidCred = false;
      return
    }


    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };
      // const custId = localStorage.getItem('ccpid');
      // if(custId){
      //   setCustomerId(custId);
      // } else { setCustomerIdError('Customer id not found!!!') }
      axios.put(apiRoutes.profit + '/customprofit', {
        'customerid': customerid,
        'principal_amount': principal_amount,
        'principal_profit_amount': principal_profit_amount,
        'total_profit': total_profit,
        'net_amount': net_amount,
        'total_withdrawal': total_withdrawal,
        'referral_principal_amount': referral_principal_amount,
        'referral_principal_profit': referral_principal_profit
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              const userData = response.data.data;
              navigate('/manage-custom-profile')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }


  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Add Custom A Profile Profit Details
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="principal_amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter principal amount*</label>
                  <input type="number" name="principal_amount" value={principal_amount} id="principal_amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setPrincipalAmount(e.target.value)} />
                  <label className="errorLabel">{principalAmountError}</label>
                </div>
                <div>
                  <label htmlFor="referral_principal_amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter total referral principal amount*</label>
                  <input type="number" name="referral_principal_amount" value={referral_principal_amount} id="referral_principal_amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setTotalReferralAmount(e.target.value)} />
                  <label className="errorLabel">{totalReferralAmountError}</label>
                </div>
                <div>
                  <label htmlFor="principal_profit_amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer principal profit amount*</label>
                  <input type="number" name="principal_profit_amount" value={principal_profit_amount} id="principal_profit_amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setPrincipalProfitAmount(e.target.value)} />
                  <label className="errorLabel">{principalProfitAmountError}</label>
                </div>
                <div>
                  <label htmlFor="referral_principal_profit" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer referral principal profit amount*</label>
                  <input type="number" name="referral_principal_profit" value={referral_principal_profit} id="referral_principal_profit" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setReferralPrincipalProfit(e.target.value)} />
                  <label className="errorLabel">{referralPrincipalProfitError}</label>
                </div>
                <div>
                  <label htmlFor="total_profit" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer total profit*</label>
                  <input type="number" name="total_profit" value={total_profit} id="total_profit" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => setTotalProfit(e.target.value)} />
                  <label className="errorLabel">{totalProfitError}</label>
                </div>
                <div>
                  <label htmlFor="net_amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer total net amount*</label>
                  <input type="number" name="net_amount" value={net_amount} id="net_amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => setNetAmount(e.target.value)} />
                  <label className="errorLabel">{netAmountError}</label>
                </div>
                <div>
                  <label htmlFor="total_withdrawal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter customer total withdrawal*</label>
                  <input type="number" name="total_withdrawal" value={total_withdrawal} id="total_withdrawal" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => setTotalWithdrawal(e.target.value)} />
                  <label className="errorLabel">{totalWithdrawalError}</label>
                </div>

                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Add profit details</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddCustomProfit