import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import logo from '../../assets/app-logo.jpg';
import apiRoutes from '../../components/common/apiRoutes.json';

function Login() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setUsernameError('')
    setPasswordError('')
    setApiError('')
    e.preventDefault();
    let isValidCred = true;

    if (username === undefined || "" === username) {
      setUsernameError("Please enter your username")
      isValidCred = false;
      return
    }
    if (password === undefined || "" === password) {
      setPasswordError("Please enter a password")
      isValidCred = false;
      return
    }

    if (username && username.length < 10) {
      setUsernameError("The username must be 10 characters or longer")
      isValidCred = false;
      return
    }


    if (password && password.length < 7) {
      setPasswordError("The password must be 8 characters or longer")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      axios.post(apiRoutes.login, { username:username, password:password })
        .then(response => {
          if(response.status === 200){
            if(response.data.status){
              const userData = response.data.data;
                localStorage.setItem('token', userData.token);
                localStorage.setItem('status', userData.status);
                localStorage.setItem('name', userData.name);
                localStorage.setItem('profession', userData.profession);
                localStorage.setItem('userpic', userData.user_img || 'noimage.png');
                const storedName = localStorage.getItem('token');
                if (storedName) {
                  navigate('/dashboard')
                }
            } else {
              setApiError(response.data.msg)
              if(response.data?.missingFields && response.data?.missingFields.length > 0){
                if(response.data?.missingFields.includes('password')){
                  setPasswordError("The password is invalid!")
                }
                if(response.data?.missingFields.includes('username')){
                  setUsernameError("The username is invalid!")
                }
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <img className="w-100 h-40 mr-2 p-2" src={logo} alt='logo' />

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Login in Admin Panel
              </h1>
              <p className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>

                <div>
                  <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your email</label>
                  <input type="text" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setUsername(e.target.value)} />
                  <label className="errorLabel">{usernameError}</label>
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                  <input type="password" name="password" id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => setPassword(e.target.value)} />
                  <label className="errorLabel">{passwordError}</label>
                </div>
                <div className="flex items-center justify-between">
                <Link to='/forgot-password'>Forgot Password</Link>
                </div>
                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login