import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function UpdateInvestmentRequest() {

  const [data, setData] = useState({});
  const [temp, setTemp] = useState();
  const [itid, setItid] = useState('');
 
  const [payment_status, setPaymentStatus] = useState();
  const [remark, setRemark] = useState("");

  let options = [
    { value: 'Pending', label: 'Pending' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Completed', label: 'Completed' },
    { value: 'On Hold', label: 'On Hold' },
    { value: 'Rejected', label: 'Rejected' },
  ];

  useEffect(() => {
    const itid = localStorage.getItem('iid');
    setItid(itid);
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };

    axios
      .post(apiRoutes.investment + '/find', { 'investment_id': `${itid}` }, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            setData(response.data.data)
            setPaymentStatus(response.data.data?.payment_status)
            setRemark(response.data.data?.remark || "")
          }
        }
      })
  }, [])


  const [paymentStatusError, setPaymentStatusError] = useState();
  const [remarkError, setRemarkError] = useState();

  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setPaymentStatusError('')
    setRemarkError('')
    setApiError('')

    e.preventDefault();
    let isValidCred = true;


    if (payment_status === undefined || payment_status === "") {
      setPaymentStatusError("Please enter valid payment status.")
      isValidCred = false;
      return
    }
    if (remark !== "" && remark.length > 200) {
      setRemarkError("Please maintain remark's length under 200 characters.")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };

      axios.put(apiRoutes.investment + '/update', {
        'investment_id': `${itid}`,
        'payment_status': payment_status,
        'remark': remark
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              navigate('/investment-requests')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }


  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Update Invensment Request
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div style={{'border':'2px solid blue', 'padding':'10px'}}> 
                <h4 className="text-s font-bold leading-tight tracking-tight text-gray-900 md:text-1xl dark:text-white"> Request details: </h4>                
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer's name: {data?.customer_details?.name}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer's bank name: {data?.customer_details?.bank_name}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer's bank account no: {data?.customer_details?.bank_acc_no}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer's bank IFSC no: {data?.customer_details?.bank_ifsc_no}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer's bank branch: {data?.customer_details?.bank_branch}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Invensment amount: {data?.amount}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Invensment transaction type: {data?.transaction_type}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer's UPI ID: {data?.upi_id}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment status: {data?.payment_status}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Note: {data?.note || ""}</label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Remark: {data?.remark || ""}</label>
                </div>
                <div>
                  <label htmlFor="payment_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select customer payment_status*</label>
                  <select name="payment_status" id="payment_status" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                    value={payment_status}
                    onChange={(e) => setPaymentStatus(e.target.value)} >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <label className="errorLabel">{paymentStatusError}</label>
                </div>
                <div>
                  <label htmlFor="remark" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter remark If required.</label>
                  <input type="text" name="remark" id="remark" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    onChange={(e) => setRemark(e.target.value)} />
                  <label className="errorLabel">{remarkError}</label>
                </div>
                
                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Update Request</button>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default UpdateInvestmentRequest