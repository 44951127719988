import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import logo from '../../assets/app-logo.jpg';
import apiRoutes from '../../components/common/apiRoutes.json';


function Signup() {
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [adhar_no, setAdhar] = useState();
  const [pan_no, setPan] = useState();
  const [profession, setProfesion] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [atcnp, setAtcnp] = useState();

  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [adharError, setAdharError] = useState("");
  const [panError, setPanError] = useState("");
  const [professionError, setProfesionError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [atcnpError, setAtcnpError] = useState("");

  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setNameError('')
    setMobileError('')
    setAtcnpError('')
    setApiError('')

    e.preventDefault();
    let isValidCred = true;

    if (name === undefined || "" === name) {
      setNameError("Please enter your name")
      isValidCred = false;
      return
    }
    if (mobile === undefined || "" === mobile) {
      setMobileError("Please enter a mobile")
      isValidCred = false;
      return
    }

    if (atcnp === undefined || atcnp === false || atcnp === 'on') {
      setAtcnpError("Please click bok to accept that you have read all terms and condition.")
      isValidCred = false;
      return
    }

    // if (username && username.length < 10) {
    //   setUsernameError("The username must be 10 characters or longer")
    //   isValidCred = false;
    //   return
    // }


    // if (password && password.length < 7) {
    //   setPasswordError("The password must be 8 characters or longer")
    //   isValidCred = false;
    //   return
    // }

    if (isValidCred) {
      axios.post(apiRoutes.signup, {
        'name': name,
        'mobile': mobile,
        'email': email,
        'adhar_no': adhar_no,
        'pan_no': pan_no,
        'profession': profession,
        'address': address,
        'city': city,
        'state': state,
        'pincode': pincode,
        'atcnp': atcnp
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              const userData = response.data.data;
              navigate('/login')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
                // if (response.data?.missingFields.includes('password')) {
                //   setPasswordError("The password is invalid!")
                // }
                // if (response.data?.missingFields.includes('username')) {
                //   setUsernameError("The username is invalid!")
                // }
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <img className="w-100 h-40 mr-2" src={logo} alt='logo' />

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign up to Fulfill your Dreams!
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your full name</label>
                  <input type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setName(e.target.value)} />
                  <label className="errorLabel">{nameError}</label>
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your email</label>
                  <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setEmail(e.target.value)} />
                  <label className="errorLabel">{emailError}</label>
                </div>
                <div>
                  <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your mobile number</label>
                  <input type="number" name="mobile" id="mobile" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="9000000001"
                    required=""
                    onChange={(e) => setMobile(e.target.value)} />
                  <label className="errorLabel">{mobileError}</label>
                </div>
                <div>
                  <label htmlFor="adhar_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your adhar card number</label>
                  <input type="text" name="adhar_no" id="adhar_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setAdhar(e.target.value)} />
                  <label className="errorLabel">{adharError}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your pan number</label>
                  <input type="text" name="pan_no" id="pan_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setPan(e.target.value)} />
                  <label className="errorLabel">{panError}</label>
                </div>
                <div>
                  <label htmlFor="profession" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your profession</label>
                  <input type="text" name="profession" id="profession" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setProfesion(e.target.value)} />
                  <label className="errorLabel">{professionError}</label>
                </div>
                <div>
                  <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your address</label>
                  <input type="text" name="address" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setAddress(e.target.value)} />
                  <label className="errorLabel">{addressError}</label>
                </div>
                <div>
                  <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your city</label>
                  <input type="text" name="city" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setCity(e.target.value)} />
                  <label className="errorLabel">{cityError}</label>
                </div>
                <div>
                  <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your state</label>
                  <input type="text" name="state" id="state" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setState(e.target.value)} />
                  <label className="errorLabel">{stateError}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your pincode</label>
                  <input type="number" name="pincode" id="pincode" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setPincode(e.target.value)} />
                  <label className="errorLabel">{pincodeError}</label>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input id="accept" aria-describedby="accept" type="checkbox" name="atcnp" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required=""
                        onChange={(e) => setAtcnp(e.target.checked)} />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="accept" className="text-gray-500 dark:text-gray-300">I have read and accepted all the terms and conditions.</label>
                    </div>
                  </div>
                  <label className="errorLabel">{atcnpError}</label>
                </div>
                {/* <Link to="/Login"></Link> */}
                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign Up</button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account <Link to='/login' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Click here to Login</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Signup