import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function CustomerDetails() {

  const [userData, setUserData] = useState({})
  const [distribitionPlans, setDistribitionPlans] = useState()
  const [temp, setTemp] = useState("")
  const [apiError, setApiError] = useState("");
  const [custId, setCustId] = useState('');
  const [status, setStatus] = useState();
  const [remark, setRemark] = useState("");
  const [profile_type, setProfileType] = useState();
  const [note, setNote] = useState("");
  const [statusError, setStatusError] = useState();
  const [remarkError, setRemarkError] = useState();
  const [profileTypeError, setProfileTypeError] = useState();
  const [noteError, setNoteError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setApiError("")
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };
    axios.get(apiRoutes.distributionplan, { headers })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status) {
            setDistribitionPlans(response.data.data);
            if (!profile_type) setProfileType(response.data.data[0].profile_type || undefined);
          } else {
            setApiError(response.data.msg)
          }
        }
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    setApiError("")

    const custId = localStorage.getItem('cid');
    setCustId(custId);

    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };
    axios.post(apiRoutes.customer + '/find', { "customerid": custId }, { headers })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status) {
            setUserData(response.data.data)
            if (response.data.data?.status) setStatus(response.data.data?.status);
            if (response.data.data?.profile_type) setProfileType(response.data.data?.profile_type);
            setNote(response.data.data?.note || "")
            setRemark(response.data.data?.remark || "")
          } else {
            setApiError(response.data.msg)
          }
        }
      })
      .catch(err => console.log(err))
  }, [])

  let options = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Active', label: 'Verification Successful' },
    { value: 'Failed', label: 'Verification Failed' },
    { value: 'Suspended', label: 'User Suspended' },
  ];


  const handleSubmit = (e) => {
    setStatusError('')
    setRemarkError('')
    setProfileTypeError('')
    setRemarkError('')
    setNoteError('')

    e.preventDefault();
    let isValidCred = true;

    if (status === undefined || status === false) {
      setStatusError("Please enter valid customer status.")
      isValidCred = false;
      return
    }
    if (profile_type === undefined || profile_type === false || profile_type === "") {
      setProfileTypeError("Please enter valid customer profile type.")
      isValidCred = false;
      return
    }
    // if (remark === undefined || remark === false || remark === "") {
    //   setRemark("")
    // }
    if (remark !== "" && remark.length > 200) {
      setRemarkError("Please maintain remark's length under 200 characters.")
      isValidCred = false;
      return
    }
    // if (note === undefined || note === false || note === "") {
    //   setNote("")
    // }
    if (note !== "" && note.length > 200) {
      setNoteError("Please maintain note's length under 200 characters.")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };

      // if (profile_type && profile_type !== "") {
      // Plan start date if its Member A or B to calculate "per_month_principal_deduction" and "plan_month_duration"
      // check if profile_type is changed or not 
      // add this feild in payload if profile_type is changed
      //   const today = new Date();
      // const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
      // console.log("== todayDate ==", todayDate)
      //   const plan_start_at = todayDate
      //   setProfileTypeError("Please enter valid customer profile type.")
      //   isValidCred = false;
      //   return
      // }

      axios.put(apiRoutes.customer + '/update', {
        'customerid': `${custId}`,
        'status': status,
        'profile_type': profile_type,
        'remark': remark,
        'note': note
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              // const userData = response.data.data;
              navigate('/manage-customers')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        {/* <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"> */}
        <div className="flex flex-col items-center justify-center">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Customer Profile Details
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User full name: {userData?.name}</label>

                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User email: {userData?.email}</label>

                </div>
                <div>
                  <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User mobile number: {userData?.mobile}</label>

                </div>
                <div>
                  <label htmlFor="adhar_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User adhar card number: {userData?.adhar_card_no}</label>

                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adhar card front side: </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/adhar/${userData?.adhar_img_f || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adhar card back side: </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/adhar/${userData?.adhar_img_b || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User pan number: {userData?.pan_card_no}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pan card : </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/pan/${userData?.pan_img || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank name: {userData?.bank_name}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank account number: {userData?.bank_acc_no}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank ifsc number: {userData?.bank_ifsc_no}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank branch: {userData?.bank_branch}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cancelled cheque/ Passbook : </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/cheque/${userData?.cc_img || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="profession" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User verification status: {userData?.status}</label>

                </div>
                <div>
                  <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User address: {userData?.address}</label>

                </div>
                <div>
                  <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User city: {userData?.city}</label>

                </div>
                <div>
                  <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User state: {userData?.state}</label>

                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User pincode: {userData?.pincode}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Note: {userData?.note}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Remark: {userData?.remark}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer photo : </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/profilepic/${userData?.customer_img || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer current profile type : {userData?.profile_type}</label>
                </div>

                <div>
                  <label htmlFor="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select customer status*</label>
                  <select name="status" id="status" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)} >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <label className="errorLabel">{statusError}</label>
                </div>
                <div>
                  <label htmlFor="profile_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select customer profile type*</label>
                  <select name="profile_type" id="profile_type" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                    value={profile_type}
                    onChange={(e) => setProfileType(e.target.value)} >
                    {distribitionPlans?.map((option) => (
                      <option key={option.profile_type}>
                        {option.profile_type}
                      </option>
                    ))}
                  </select>
                  <label className="errorLabel">{profileTypeError}</label>
                </div>
                <div>
                  <label htmlFor="remark" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter remark If required.</label>
                  <input type="text" name="remark" id="remark" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    onChange={(e) => setRemark(e.target.value)} />
                  <label className="errorLabel">{remarkError}</label>
                </div>
                <div>
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter note if required</label>
                  <input type="text" name="note" id="note" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    onChange={(e) => setNote(e.target.value)} />
                  <label className="errorLabel">{noteError}</label>
                </div>
                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit Request</button>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomerDetails