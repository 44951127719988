import {
	HiOutlineViewGrid,
	HiOutlineCube,
	HiOutlineUsers,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog
} from 'react-icons/hi'

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/dashboard',
		icon: <HiOutlineViewGrid />
	},
	// {
	// 	key: 'documents',
	// 	label: 'Manage Documents',
	// 	path: '/manage-documents',
	// 	icon: <HiOutlineShoppingCart />
	// },
	{
		key: 'investments',
		label: 'Investments requests',
		path: '/investment-requests',
		icon: <HiOutlineUsers />
	},
	{
		key: 'withdrawal',
		label: 'Withdrawal requests',
		path: '/withdrawal-requests',
		icon: <HiOutlineUsers />
	},
	{
		key: 'user',
		label: 'Manage users',
		path: '/manage-users',
		icon: <HiOutlineUsers />
	},
	{
		key: 'distribution-plans',
		label: 'Distribution Plans',
		path: '/distribution-plans',
		icon: <HiOutlineUsers />
	},
	{
		key: 'customer',
		label: 'Manage customers',
		path: '/manage-customers',
		icon: <HiOutlineUsers />
	},
	{
		key: 'custom-profile',
		label: 'Manage custom profile profit',
		path: '/manage-custom-profile',
		icon: <HiOutlineUsers />
	},
	{
		key: 'reports',
		label: 'Reports',
		path: '/reports',
		icon: <HiOutlineCube />
	}
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	{
		key: 'Terms and Conditions',
		label: 'Terms and Conditions',
		path: '/terms-and-conditions',
		icon: <HiOutlineCog />
	},
	{
		key: 'Privacy Policy',
		label: 'Privacy Policy',
		path: '/privacy-policy',
		icon: <HiOutlineQuestionMarkCircle />
	}
	// {
	// 	key: 'Profile',
	// 	label: 'Account Details',
	// 	path: '/profile',
	// 	icon: <HiOutlineQuestionMarkCircle />
	// }
]
