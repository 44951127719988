import React from 'react'
import InvestmentTransactions from '../components/InvestmentTransactions'
import { Link, useNavigate } from 'react-router-dom'


function InvestmentHistory() {
  return (
    <div>
      <div></div>
      {/* <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center" style={{'justify-content': 'flex-end'}}>
          <div className="pl-4 pr-4">
            <button className='investmentbtn bg-green-600 p-3 text-white rounded-md' type='button'>
              <Link to='/investment-request' style={{ 'color': 'white' }}>Add Investment Transaction</Link>
            </button>
          </div>
        </div> */}
      <div className="flex flex-row gap-4 w-full">        
        <InvestmentTransactions />
      </div>
    </div>
  )
}

export default InvestmentHistory