import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import logo from '../../assets/app-logo.jpg';
import apiRoutes from '../../components/common/apiRoutes.json';

function ForgotPass() {
  const [email, setEmail] = useState();
  const [firstStep, setFirstStep] = useState(true);
  const [count, setCount] = useState(0);
  const [emailError, setEmailError] = useState("");
  const [otp, setOtp] = useState();
  const [otpError, setOtpError] = useState("");
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState("");
  const [re_password, setRePassword] = useState();
  const [rePasswordError, setRePasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const handleEmailSubmit = (e) => {
    setEmailError('')
    setApiError('')
    e.preventDefault();
    let isValidCred = true;

    if (email === undefined || "" === email) {
      setEmailError("Please enter your registered email address")
      isValidCred = false;
      return
    }
    // if (password === undefined || "" === password) {
    //   setPasswordError("Please enter a password")
    //   isValidCred = false;
    //   return
    // }

    if (isValidCred) {
      axios.post(apiRoutes.forgot, { email: email })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              setFirstStep(false)
            } else {
              setApiError(response.data.msg)
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  const reSendOtp = (e) => {
    setApiError('')
    let isValidCred = true;
    e.preventDefault();

    if (isValidCred && count < 3) {
      setCount(count + 1)
      axios.post(apiRoutes.resendotp, { email: email })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              alert("OTP sent successfully on your registerd email address!")
            } else {
              setApiError(response.data.msg)
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  const handleOtpSubmit = (e) => {
    setOtpError('')
    setPasswordError('')
    setRePasswordError('')
    setApiError('')
    e.preventDefault();
    let isValidCred = true;
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;

    if (otp === undefined || "" === otp) {
      setOtpError("The enter valid OTP!")
      isValidCred = false;
      return
    }
    if (otp && otp.length !== 6) {
      setOtpError("The enter valid OTP!")
      isValidCred = false;
      return
    }
    if (password === undefined || "" === password || !regex.test(password)) {
      setPasswordError("Please enter valid new password")
      isValidCred = false;
      return
    }
    if (re_password === undefined || "" === re_password || re_password !== password || !regex.test(re_password)) {
      setRePasswordError("Please re-enter new password. Re-entered password and new password must be same")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      axios.post(apiRoutes.verifyotp, { email: email, otp: otp, password: password, re_password: re_password })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              const userData = response.data.data;
              alert("New password updated successfully. Please login with new password!")
              navigate('/login')
            } else {
              setApiError(response.data.msg)
              // if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              //   if (response.data?.missingFields.includes('password')) {
              //     setPasswordError("The password is invalid!")
              //   }
              //   if (response.data?.missingFields.includes('username')) {
              //     setUsernameError("The username is invalid!")
              //   }
              // }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <img className="w-100 h-40 mr-2 p-2" src={logo} alt='logo' />

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Reset your password!
              </h1>
              <p className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white errorLabel">{apiError}</p>
              {firstStep ? (<form className="space-y-4 md:space-y-6" onSubmit={handleEmailSubmit}>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your registered email address</label>
                  <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setEmail(e.target.value)} />
                  <label className="errorLabel">{emailError}</label>
                </div>
                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send OTP</button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Go to login page <Link to='/login' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</Link>
                </p>
              </form>)
                : (<form className="space-y-4 md:space-y-6" onSubmit={handleOtpSubmit}>
                  <div>
                    <label htmlFor="otp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter OTP.</label>
                    <input type="number" name="otp" id="otp" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="OTP"
                      required=""
                      onChange={(e) => setOtp(e.target.value)} />
                    <label className="errorLabel">{otpError}</label>
                  </div>
                  <div>
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                    <input type="password" name="password" id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                      onChange={(e) => setPassword(e.target.value)} />
                    <label className="errorLabel">{passwordError}</label>
                  </div>
                  <div>
                    <label htmlFor="re_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Re-enter New Password</label>
                    <input type="password" name="re_password" id="re_password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                      onChange={(e) => setRePassword(e.target.value)} />
                    <label className="errorLabel">{rePasswordError}</label>
                  </div>
                  {count < 3 ? (<p className="text-sm font-light text-gray-500 dark:text-gray-400" style={{ 'float': 'right' }}>
                    <Link onClick={reSendOtp}>Re-send OTP!</Link></p>) : (<p className="text-sm font-light text-gray-500 dark:text-gray-400" style={{ 'float': 'right' }}>Re-send OTP limit exceeded</p>)}

                  <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Update Password</button>
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Go to login page <Link to='/login' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</Link>
                  </p>
                  <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Note: Password must meet following guidelines.</label>
                  <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
                    <li>1] At least one lowercase alphabet i.e. [a-z]</li>
                    <li>2] At least one uppercase alphabet i.e. [A-Z]</li>
                    <li>3] At least one Numeric digit i.e. [0-9]</li>
                    <li>4] At least one special character i.e. ['@', '$', '.', '#', '!', '%', '*', '?', '&', '^']</li>
                    <li>5] Also, the total length of password between 8 to 15 character</li>
                  </ol>
                </form>)}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ForgotPass