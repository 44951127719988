import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function DistributionPlanList() {

  const [data, setData] = useState([])
  const [temp, setTemp] = useState("")

  const navigate = useNavigate();

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };

    axios
      .get(apiRoutes.distributionplan, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            setData(response.data.data)
          }
        }
      })
  }, [temp])

  // const viewRequest = (id,action) => {
  //   localStorage.setItem('uid', id);
  //   if(action === 'Edit'){
  //     navigate('/update-user')
  //   } else { navigate('/update-user') }
  // }

  return (
    <div>
      <div></div>
      {/* <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center" style={{ 'justify-content': 'flex-end' }}>
        <div className="pl-4 pr-4">
          <button className='investmentbtn bg-green-600 p-3 text-white rounded-md' type='button'>
            <Link to='/investment-request' style={{ 'color': 'white' }}>Add User</Link>
          </button>
        </div>
      </div> */}
      <div className="flex flex-row gap-4 w-full">
        <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
          <strong className="text-gray-700 font-medium">List of Distribution Plans</strong>
          <div className="border-x border-gray-200 rounded-sm mt-3">
            <table className="w-full text-gray-700">
              <thead>
                <tr>
                <th>Sr. No.</th>
                  <th>Plan Name</th>
                  <th>Plan Profile Type</th>
                  <th>Plan Details</th>
                  <th>Plan Per Month Duration</th>
                  <th>Plan Per Month Profit</th>
                  <th>Plan Per Month Principal Deduction</th>
                  <th>Plan Per Month Referral Profit</th>
                  <th>Plan Status</th>
                  {/* <th>View</th>
                  <th>Edit</th> */}
                </tr>
              </thead>
              <tbody>
                {data.map((plan, index) => (
                  <tr key={index+1}>
                    <td> {index+1}] </td>
                    <td> {plan.plan_name} </td>
                    <td> {plan.profile_type} </td>
                    <td> {plan.plan_details} </td>
                    <td> {plan.plan_month_duration} </td>
                    <td> {plan.per_month_profit} </td>
                    <td> {plan.per_month_principal_deduction} </td>
                    <td> {plan.per_month_referral_profit} </td>
                    <td> {plan.status} </td>
                    {/* <td onClick={() => viewRequest(`${plan._id}`,'View')}>View</td>
                    <td onClick={() => viewRequest(`${plan._id}`,'Edit')}>Edit</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DistributionPlanList